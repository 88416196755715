import * as Yup from 'yup';
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Iconify from '../../components/Iconify';

export default function RegisterForm({ onSuccess }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(24, 'Too Long!')
      .required('First name is required')
      .matches(
        /^[A-Za-z\d ]+$/,
        'Your information must be entered in English or another Latin character-based language'
      )
      .matches(/^\S/, 'First name is required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(24, 'Too Long!')
      .required('Last name is required')
      .matches(
        /^[A-Za-z\d ]+$/,
        'Your information must be entered in English or another Latin character-based language'
      )
      .matches(/^\S/, 'Last name is required'),
    email: Yup.string()
      .email('Email must be a valid email address')
      .min(6, 'Too Short!')
      .max(64, 'Too Long!')
      .required('Email is required')
      .matches(/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, 'Email must be a valid email address'),
    password: Yup.string()
      .required('Password is required')
      .max(64, 'Too Long!')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character (! # $ % & * ? @)'
      ),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (data, { setSubmitting }) => {
      const { email, password, firstName, lastName } = data;
      Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          'custom:firstName': firstName,
          'custom:lastName': lastName,
        },
      })
        .then(() => {
          onSuccess({ email, password });
        })
        .catch((err) => {
          enqueueSnackbar(err.message, {
            variant: 'error',
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Sign up
      </Typography>
      <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="First name"
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                fullWidth
                label="Last name"
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>

            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Register
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
