import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

import { Auth } from 'aws-amplify';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, IconButton, InputAdornment, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Iconify from '../../components/Iconify';

export default function ChangePasswordForm({ handleChangePass }) {
  const { enqueueSnackbar } = useSnackbar();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const ValidationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old password is required'),
    password: Yup.string()
      .required('Password is required')
      .max(64, 'Too Long!')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character (! # $ % & * ? @)'
      ),
    password2: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      password2: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: (data, { setSubmitting }) => {
      const { oldPassword, password } = data;

      Auth.currentAuthenticatedUser()
        .then((user) => Auth.changePassword(user, oldPassword, password))
        .then(handleChangePass)
        .catch((err) =>
          enqueueSnackbar(err.message, {
            variant: 'error',
          })
        )
        .finally(() => setSubmitting(false));
    },
  });

  const handleShowOldPassword = () => {
    setShowOldPassword((show) => !show);
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowPassword2 = () => {
    setShowPassword2((show) => !show);
  };

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showOldPassword ? 'text' : 'password'}
            label="Old Password"
            {...getFieldProps('oldPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowOldPassword} edge="end">
                    <Iconify icon={showOldPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.oldPassword && errors.oldPassword)}
            helperText={touched.oldPassword && errors.oldPassword}
          />
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            type={showPassword2 ? 'text' : 'password'}
            label="Confirm Password"
            {...getFieldProps('password2')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword2} edge="end">
                    <Iconify icon={showPassword2 ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password2 && errors.password2)}
            helperText={touched.password2 && errors.password2}
          />
        </Stack>

        <Stack direction="row" alignItems="center">
          <Button fullWidth size="large" variant="contained" sx={{ my: 2, mr: 3 }} onClick={handleChangePass}>
            Cancel
          </Button>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ my: 2 }}>
            Send
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
