import { Container, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------

export default function DeviceGrafana() {
  const navigation = useNavigate();
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Button
          sx={{ my: 1 }}
          variant="contained"
          onClick={() => navigation('/dashboard/devices-list', { replace: true })}
          startIcon={<Iconify icon="akar-icons:arrow-left" />}
        >
          Go Back
        </Button>
      </Container>
      <iframe
        title="grafana"
        src="http://sn123456.dashboards.rnss.klika-tech.com:3000"
        style={{ width: '100%', height: '80vh', border: 'none' }}
      />
    </Page>
  );
}
