import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
import Page from '../components/Page';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function NginxError() {
  return (
    <Page title="Error">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            We're sorry!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>Something went wrong. We're working on the problem.</Typography>
          <Typography sx={{ color: 'text.secondary' }}>Please try again later.</Typography>

          <Button to="/" size="large" variant="contained" component={RouterLink} sx={{ m: 3 }}>
            Go to Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
