import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { setUser } from '../../store/user/userSlice';

// ----------------------------------------------------------------------

export default function ProfileForm({ handleEditProfile }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(24, 'Too Long!')
      .required('First name is required')
      .matches(
        /^[A-Za-z\d ]+$/,
        'Your information must be entered in English or another Latin character-based language'
      )
      .matches(/^\S/, 'First name is required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(24, 'Too Long!')
      .required('Last name is required')
      .matches(
        /^[A-Za-z\d ]+$/,
        'Your information must be entered in English or another Latin character-based language'
      )
      .matches(/^\S/, 'Last name is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (data, { setSubmitting }) => {
      const { firstName, lastName } = data;

      Auth.currentAuthenticatedUser()
        .then((user) => Auth.updateUserAttributes(user, { 'custom:firstName': firstName, 'custom:lastName': lastName }))
        .then(() => {
          dispatch(setUser({ ...user, firstName, lastName }));
          localStorage.setItem('user', JSON.stringify({ ...user, firstName, lastName }));
          handleEditProfile();
        })
        .catch((err) =>
          enqueueSnackbar(err.message, {
            variant: 'error',
          })
        )
        .finally(() => setSubmitting(false));
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <Stack direction="row" alignItems="center">
            <Button fullWidth size="large" variant="contained" sx={{ my: 2, mr: 3 }} onClick={handleEditProfile}>
              Cancel
            </Button>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ my: 2 }}
            >
              Send
            </LoadingButton>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
