import { Typography, Button, Stack, Accordion, AccordionSummary, AccordionDetails, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import axios from 'axios';

import Iconify from '../../../components/Iconify';
import { downloadCert } from '../../../utils/api';

export default function DeviceAccordion() {
  const device = useSelector((state) => state.device);
  const { enqueueSnackbar } = useSnackbar();
  const [dashboardAvailable, setDashboardAvailable] = useState(true);
  const [checkingStatus, setCheckingStatus] = useState(false);

  const serialNumber = device?.serialNumber?.toLowerCase();
  const provisioned = device?.provisioned;
  const deviceStatus = device?.status;

  // Check dashboard availability on component mount and whenever serialNumber changes
  useEffect(() => {
    if (serialNumber) {
      checkDashboardAvailability();
    }
  }, [serialNumber]);

  const checkDashboardAvailability = async () => {
    if (!serialNumber) return;
    
    setCheckingStatus(true);
    try {
      // Using the dashboard URL with serialNumber to check if it's accessible
      const dashboardUrl = `${process.env.REACT_APP_DASHBOARD_URL}/${serialNumber}`;
      
      // We'll use a HEAD request with a short timeout to check if the server responds
      // Setting a short timeout to prevent long waiting times for the user
      await axios.head(dashboardUrl, { 
        timeout: 5000,
        // Handling CORS by using a proxy or setting withCredentials
        // This might need adjustment based on your setup
        withCredentials: false 
      });
      
      setDashboardAvailable(true);
    } catch (error) {
      console.error('Dashboard unavailable:', error);
      setDashboardAvailable(false);
    } finally {
      setCheckingStatus(false);
    }
  };

  const handleDownloadCert = (sn) => {
    downloadCert(sn)
      .then((res) => {
        window.open(res?.data);
      })
      .catch((e) => {
        enqueueSnackbar(e?.response?.data?.message, {
          variant: 'error',
        });
      });
  };

  const onClickGrafana = (sn) => {
    if (sn) window.open(`${process.env.REACT_APP_DASHBOARD_URL}/${sn}`);
  };

  const getStatus = (statusKey, provisioned_, dashboardAvailable_) => {
    // First check if dashboard is unavailable
    if (provisioned_ && !dashboardAvailable_) {
      return { title: 'Dashboard Offline', color: 'orange' };
    }
    
    // Then check the regular status
    switch (statusKey) {
      case 'AVAILABLE':
        if (provisioned_) return { title: 'Provisioned', color: 'green' };
        else return { title: 'Under Provisioning', color: 'orange' };
      case 'quarantine':
        return { title: 'Quarantined', color: 'red' };
      case 'ERROR':
        return { title: 'Error', color: 'red' };
      default:
        return { title: 'Provisioned', color: 'green' };
    }
  };

  const status = getStatus(deviceStatus, provisioned, dashboardAvailable);
  const disableButtons = !provisioned || deviceStatus === 'quarantine' || !dashboardAvailable;

  // Manual refresh of dashboard status
  const refreshDashboardStatus = () => {
    if (!checkingStatus) {
      checkDashboardAvailability();
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        sx={{
          boxShadow: 0,
          borderRadius: 1,
          color: (theme) => theme.palette.primary.darker,
          bgcolor: (theme) => theme.palette.primary.lighter,
          cursor: 'pointer',
        }}
        expandIcon={<Iconify icon="flat-color-icons:expand" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="subtitle1" sx={{ width: '75%', flexShrink: 0 }}>
          Device 1
        </Typography>
        <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
          Status:
          <Typography variant="subtitle1" sx={{ marginLeft: 1, color: status?.color }}>
            {status?.title}
          </Typography>
          {checkingStatus && (
            <CircularProgress size={16} sx={{ ml: 1 }} />
          )}
          {provisioned && (
            <Button 
              size="small" 
              onClick={(e) => {
                e.stopPropagation();
                refreshDashboardStatus();
              }}
              sx={{ ml: 1, minWidth: 'auto', p: 0 }}
            >
              <Iconify icon="eva:refresh-outline" width={16} height={16} />
            </Button>
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {deviceStatus === 'ERROR' && (
          <Typography variant="h6" gutterBottom sx={{ color: 'red' }}>
            Error:
            <Typography gutterBottom>{device?.status_message}</Typography>
          </Typography>
        )}
        {!dashboardAvailable && provisioned && (
          <Typography variant="h6" gutterBottom sx={{ color: 'orange' }}>
            Dashboard Notice:
            <Typography gutterBottom>
              The dashboard is currently offline. This may be because the EC2 instance is stopped.
              Please try again later or contact support if this persists.
            </Typography>
          </Typography>
        )}
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          <Stack>
            <Typography variant="h6" gutterBottom>
              Account name:
              <Typography gutterBottom>{device?.username}</Typography>
            </Typography>
            <Typography variant="h6" gutterBottom>
              Email:
              <Typography gutterBottom>{device?.email}</Typography>
            </Typography>
            <Typography variant="h6" gutterBottom>
              UUID:<Typography gutterBottom>{serialNumber}</Typography>
            </Typography>
          </Stack>

          <Stack>
            <Button
              variant="contained"
              disabled={disableButtons}
              onClick={() => handleDownloadCert(serialNumber)}
              startIcon={<Iconify icon="eva:download-outline" />}
            >
              Download certificate
            </Button>
            <Button
              sx={{ my: 1 }}
              disabled={disableButtons}
              variant="contained"
              onClick={() => onClickGrafana(serialNumber)}
              endIcon={<Iconify icon="akar-icons:arrow-right" />}
            >
              Go to Dashboard
            </Button>
            {!dashboardAvailable && provisioned && (
              <Button
                variant="outlined"
                color="warning"
                onClick={refreshDashboardStatus}
                startIcon={<Iconify icon="eva:refresh-outline" />}
                disabled={checkingStatus}
              >
                {checkingStatus ? 'Checking...' : 'Check Dashboard Status'}
              </Button>
            )}
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}