import React, { useState } from 'react';
import { 
  Grid, 
  Container, 
  Typography, 
  Stack, 
  Button, 
  Radio, 
  RadioGroup,
  FormControlLabel, 
  FormLabel, 
  FormControl, 
  Alert,
  Snackbar,
  CircularProgress
} from '@mui/material';
import { useSelector } from 'react-redux';

import Page from '../components/Page';
import ProfileForm from '../sections/profile/ProfileForm';
import ChangePasswordForm from '../sections/profile/ChangePasswordForm';
import { creditCardchange } from '../utils/api';

// ----------------------------------------------------------------------

export default function Profile() {
  const user = useSelector((state) => state.user);
  const device = useSelector((state) => state.device);
  const [changePass, setChangePass] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [paymentPreference, setPaymentPreference] = useState("no");
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  const serialNumber = user?.sn?.toLowerCase();

  const handleChangePass = () => {
    setChangePass(!changePass);
  };

  const handleEditProfile = () => {
    setEditProfile(!editProfile);
  };

  const handleChange = (event) => {
    setPaymentPreference(event.target.value);
  };

  const handleCloseNotification = () => {
    setNotification({...notification, open: false});
  };

  const handleEditCCUpdate = () => {
    if (!serialNumber) {
      setNotification({
        open: true,
        message: 'No device registered. Please register a device first.',
        severity: 'error'
      });
      return;
    }

    setLoading(true);
    
    const userPref = {
      sn: serialNumber,
      choice: paymentPreference
    };
    
    creditCardchange(userPref)
      .then(() => {
        setNotification({
          open: true,
          message: 'Payment preference updated successfully!',
          severity: 'success'
        });
      })
      .catch((error) => {
        console.error('Error updating payment preference:', error);
        setNotification({
          open: true,
          message: error?.response?.data?.message || 'Failed to update payment preference. Please try again later.',
          severity: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Page title="Profile">
      <Container maxWidth="xl">
        <Grid container spacing={2} direction="column" justify="center">
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Profile Page
            </Typography>
            {editProfile ? (
              <ProfileForm handleEditProfile={handleEditProfile} />
            ) : (
              <Stack>
                <Typography variant="h6" gutterBottom>
                  First name:
                  <Typography gutterBottom>{user?.firstName}</Typography>
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Last name:
                  <Typography gutterBottom>{user?.lastName}</Typography>
                </Typography>

                {changePass ? (
                  <ChangePasswordForm handleChangePass={handleChangePass} />
                ) : (
                  <>
                    <Button variant="contained" sx={{ width: 350, my: 1 }} onClick={handleEditProfile}>
                      Edit Profile
                    </Button>
                    <Button variant="contained" sx={{ width: 350, my: 1 }} onClick={handleChangePass}>
                      Change Password
                    </Button>
                  </>
                )}
                
                <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                  Payment Preference
                </Typography>
                <Stack spacing={2} sx={{ maxWidth: 500 }}>
                  <Alert severity="info">
                    In order for you take take complete ownership of your AWS account, you must update the account with your own payment details.
                  </Alert>
                  
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Select whether you added a credit card to your aws account:</FormLabel>
                    <RadioGroup
                      row
                      name="payment-preference"
                      value={paymentPreference}
                      onChange={handleChange}
                    >
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                  
                  <Button
                    variant="contained"
                    onClick={handleEditCCUpdate}
                    disabled={loading || !serialNumber}
                    sx={{ width: 350, my: 1 }}
                  >
                    {loading ? (
                      <>
                        <CircularProgress size={24} sx={{ mr: 1 }} />
                        Updating...
                      </>
                    ) : (
                      'Payment Details Updated'
                    )}
                  </Button>
                  
                  {!serialNumber && (
                    <Alert severity="warning">
                      You need to register a device before updating payment preferences.
                    </Alert>
                  )}
                </Stack>
              </Stack>
            )}
          </Grid>
        </Grid>
        
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
        >
          <Alert 
            onClose={handleCloseNotification} 
            severity={notification.severity} 
            sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </Container>
    </Page>
  );
}