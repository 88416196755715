import axios from 'axios';
import { Auth } from 'aws-amplify';
import { Alert } from '@mui/material'

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      Auth.currentAuthenticatedUser().then((cognitoUser) => {
        Auth.currentSession().then((currentSession) => {
          cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
            const idToken = session?.idToken?.jwtToken;
            localStorage.setItem('token', idToken);

            const { config } = error;
            config.headers.Authorization = idToken;
            return axios.request(config);
          });
        });
      });
    }
    return Promise.reject(error);
  }
);

const baseURL = process.env.REACT_APP_API_URL;

export const getDevice = ({ sn }) => {
  const token = localStorage.getItem('token');
  return axiosInstance.get(`${baseURL}/get?sn=${sn}`, {
    headers: {
      Authorization: token,
    },
  });
};

export const registerDevice = (device) => {
  const token = localStorage.getItem('token');
  return axiosInstance.post(`${baseURL}/start`, device, {
    headers: { Authorization: token },
  });
};

// Just the creditCardchange function from utils/api.js
export const creditCardchange = (prefdata) => {
  const token = localStorage.getItem('token');
  
  // Validate input before making API call
  if (!prefdata || !prefdata.sn || !prefdata.choice) {
    return Promise.reject(new Error('Missing required parameters: sn or choice'));
  }
  
  // Ensure choice is valid
  if (prefdata.choice !== 'yes' && prefdata.choice !== 'no') {
    return Promise.reject(new Error('Choice must be either "yes" or "no"'));
  }
  
  return axiosInstance.post(`${baseURL}/updatecc`, prefdata, {
    headers: { Authorization: token }
  })
  .then(response => {
    // Return the response data to be handled by the component
    return response.data;
  })
  .catch(error => {
    // Log the error for debugging
    console.error('Credit card preference update failed:', error);
    
    // Handle different error scenarios
    if (!error.response) {
      // Network error
      throw new Error('Network error. Please check your connection and try again.');
    } else if (error.response.status === 401) {
      // Authentication error - will be handled by the axios interceptor
      throw new Error('Authentication required. Please log in again.');
    } else if (error.response.status === 404) {
      // Resource not found
      throw new Error('Device not found or account not available.');
    } else if (error.response.status === 502) {
      // Gateway error
      throw new Error('Service temporarily unavailable. Please try again later.');
    } else {
      // Pass through the server error message if available
      throw error;
    }
  });
};


export const downloadCert = (sn) => {
  const token = localStorage.getItem('token');
  return axiosInstance.post(
    `${baseURL}/get-download-url`,
    { sn },
    {
      headers: { Authorization: token },
    }
  );
};

export const checkbudget = (email) => {
  const token = localStorage.getItem('token');
  return axiosInstance.post(
    `${baseURL}/checkbudget`,
    { email },
    {
      headers: { Authorization: token },
    }
  )
  .then(response => {
    // Check if the response status is 200 (success)
    console.log(response);
    if (response.status === 200) {
      // Show a success message
      console.log("Account usage above 80 %");
      alert('You have exceeded 80% of Credit usage. Update your Payment preferences to have unrestricted access to all resources');
    } else {
      // Show a failure message for other status codes
      console.log("Account usage under 80 %");
    }
    return response; // Pass the response along
  })
/*   .then((data) => {
    const usageValue = data.Usage;
    console.log(usageValue)
    // alert('Usage value: ' + usageValue);
  });  */
};

export const resetGrafanaPassword = ({ sn }) => {
  const token = localStorage.getItem('token');
  return axiosInstance.post(`${baseURL}/reset?sn=${sn.toLowerCase()}`, null, {
    headers: {
      Authorization: token,
    },
  });
};
