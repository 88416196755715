import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import CloudIcon from '../components/CloudIcon';
// sections
import { LoginForm, ConfirmEmailForm } from '../sections/auth';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  marginTop: 70,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    marginTop: 250,
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  height: '60vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const [user, setUser] = useState(null);
  const smUp = useResponsive('up', 'sm');
  const theme = useTheme();

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          {smUp && <CloudIcon />}
          {smUp && (
            <Typography variant="body2">
              Don’t have an account? {''}
              <Link
                variant="subtitle2"
                component={RouterLink}
                to="/register"
                sx={{ color: theme.palette.primary.darker }}
              >
                Sign up
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        <Container maxWidth="sm">
          <ContentStyle>
            {!!user ? <ConfirmEmailForm user={user} /> : <LoginForm onNotConfirmedUser={(user_) => setUser(user_)} />}

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link
                  variant="subtitle2"
                  component={RouterLink}
                  to="/register"
                  sx={{ color: theme.palette.primary.darker }}
                >
                  Sign up
                </Link>
              </Typography>
            )}
            {!smUp && <CloudIcon />}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
