import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Auth } from 'aws-amplify';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function ConfirmNewPassword({ email }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const ValidationSchema = Yup.object().shape({
    verificatoinCode: Yup.string()
      .required('Verification code is required')
      .max(6, 'Verificatoin code must be at most 6 characters'),
    password: Yup.string()
      .required('Password is required')
      .max(64, 'Too Long!')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character (! # $ % & * ? @)'
      ),
    password2: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const formik = useFormik({
    initialValues: {
      verificatoinCode: '',
      password: '',
      password2: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: (data, { setSubmitting }) => {
      const { verificatoinCode, password } = data;

      Auth.forgotPasswordSubmit(email, verificatoinCode?.toString(), password)
        .then(() => {
          setSubmitting(false);
          enqueueSnackbar('Password changed successfully!', {
            variant: 'success',
          });
          navigate('/login', { replace: true });
        })
        .catch((err) => {
          setSubmitting(false);
          enqueueSnackbar(err.message, {
            variant: 'error',
          });
        });
    },
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowPassword2 = () => {
    setShowPassword2((show) => !show);
  };

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Verification code"
            type="number"
            {...getFieldProps('verificatoinCode')}
            error={Boolean(touched.verificatoinCode && errors.verificatoinCode)}
            helperText={touched.verificatoinCode && errors.verificatoinCode}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword2 ? 'text' : 'password'}
            label="Confirm Password"
            {...getFieldProps('password2')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword2} edge="end">
                    <Iconify icon={showPassword2 ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password2 && errors.password2)}
            helperText={touched.password2 && errors.password2}
          />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ my: 2 }}>
          Send
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
