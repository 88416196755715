import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Link, Container, Typography } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import Page from '../components/Page';
import { RegisterForm, ConfirmEmailForm } from '../sections/auth';
import CloudIcon from '../components/CloudIcon';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  marginTop: 70,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    marginTop: 300,
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  height: '70vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function Register() {
  const [user, setUser] = useState(null);
  const smUp = useResponsive('up', 'sm');
  const theme = useTheme();

  return (
    <Page title="Register">
      <RootStyle>
        <HeaderStyle>
          {smUp && <CloudIcon />}
          {smUp && (
            <Typography variant="body2">
              Already have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to="/login" sx={{ color: theme.palette.primary.darker }}>
                Login
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        <Container>
          <ContentStyle>
            {!!user ? <ConfirmEmailForm user={user} /> : <RegisterForm onSuccess={(user_) => setUser(user_)} />}

            {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                Already have an account?{' '}
                <Link
                  variant="subtitle2"
                  to="/login"
                  component={RouterLink}
                  sx={{ color: theme.palette.primary.darker }}
                >
                  Login
                </Link>
              </Typography>
            )}
            {!smUp && <CloudIcon />}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
