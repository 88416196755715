import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography, Link, Stack } from '@mui/material';

import Page from '../components/Page';

const links = [
  { title: 'CK-RA6M5 Kit Information', link: 'https://renesas.com/ra/ck-ra6m5' },
  { title: 'CK-RX65N Kit Information', link: 'https://renesas.com/rx/ck-rx65n' },
  { title: 'Renesas RA RX cloud solutions', link: 'https://renesas.com/cloudsolutions' },
  { title: 'RA Product Information', link: 'https://renesas.com/ra' },
  { title: 'RX Product Information', link: 'https://renesas.com/rx' },
  { title: 'RA Product Support Forum', link: 'https://renesas.com/ra/forum' },
  { title: 'RX Product Support Forum', link: 'https://renesas.com/rx/forum' },
  { title: 'Renesas Support', link: 'https://renesas.com/support' },
];

const StyledLinkItem = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.darker,
  cursor: 'pointer',
  margin: 5,
}));

export default function SupportPage() {
  const onClickLink = (link) => {
    window.open(link);
  };

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={2} direction="column" justify="center">
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Support Page
            </Typography>

            <Stack flexDirection="column">
              {links.map((linkItem) => (
                <StyledLinkItem key={linkItem?.title} variant="subtitle1" onClick={() => onClickLink(linkItem?.link)}>
                  {linkItem?.title}
                </StyledLinkItem>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
